#menu{
  background-color: $header-text-color;
  border-radius: 0;
  border: none;
  height: $menu-height;


  /* Hide button in mobile */
  .btn-hide{

    @include btn-hide-properties($header-text-color,30px);

    position: absolute;
    margin-top: 13px;
    right:0;
    margin-right: 15px;

  }

  @include menu-dropdown-style();


  /*Menu Elements properties*/
  li{

    a{
      background-color: $header-text-color;
      color: $white;
      font-size: 17px;
      font-weight: lighter;
      line-height: 65px;
      padding-top: 0;
      padding-bottom: 0;
      cursor: pointer;
      /* Include the arrows*/
      @include add-before-icon-menu("\f0a9");
      /* Settings the arrows position*/
      &:after{
        font-size: 28px;
        position: relative;
        top: 3px;
      }
    }
  }
  /*The list button properties*/
  .opt-language{
    float: right;

  /* Set the correct arrow position*/
    a{
      &:after{
        top:5px;
      }

    }
  }
}

/*Hamburger mobile menu */
#hamburger{

  float: left;

  span{
    background-color: white;
    height: 5px;
    width: 31px;
    border-radius: 1px;
  }
}
/*Margin between lines in hamburger mobile menu*/
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 5px;
}


/* Behavior in mobile */
@media only screen and (max-width: 767px){


  /* Styles for the Dropdowns*/
  .options-dropdown-menu{

    padding: 10px;
    width: 210px;
    background-color: $header-text-color;
    margin-top: -1px;
    border:none ;
    border-radius: 0;
    form{

      text-align: center;

      label{
        color: $white;
      }

      input{
        border-radius: 0px;
        margin-top: 5px;
      }

      button{
        margin-top: 5px;
        font-family: $fontRoboto;
        background-color: $light-grey;
        border: none;
      }
    }
  }


  #menu{

    background-color: $header-text-color;
    position: absolute;
    z-index: 10;
    top: 0;
    border: none;
    height: $size-head-in-mobile;


    .options-dropdown-menu{

      width: 100%;
    }

    #js-bootstrap-offcanvas{

      background-color: $white;
      overflow: auto;

      /* Remove the arrows in mobile */
      li{

        a{
          background-color: transparent;
          color:$header-text-color;
          &:after{
            display: none;
          }
        }
      }

      hr{
        border-top: 1px solid $header-text-color;
        width: 95%;
      }

    /* Social media icons in mobile.*/
      .social-mobile{
        margin-top: 15px;

        text-align: center;

        @include setSocialIcons ($header-text-color,25px);

        .fa{
          margin-left: 5%;
          margin-right:5%;
        }
      }

    /* Styles for list options in mobile*/
      .opt-language{
        float: none;
      }
    }
  }
}