
/*this file content the css for fottrt section of the page*/
.not-padding{
  padding-left: 0px;
  padding-right: 0px;
}
.footer-custom {
  @media (min-width: 769px){ margin-top: 218px; }
  @media (max-width: 768px){ margin-top: 30px; }
  height: auto;
  width: 100%;
  .footer-social {
    a {
      color:$white;
      font-size: $size-small-8;
      i {
        width: 38px;
      }
    }
    @include footer-section($white,$green-grey,55px,29px);
    .main-text {
      font-size: $size-large-x;
      font-weight: 200;
    }
    .social {
      margin-top: 4px;
      @media (max-width: 768px) and (min-width: 300px) {
        text-align: center;
      }
      @media (max-width: 990px) and (min-width: 769px) {
        text-align: right;
      }
    }
  }

  .footer-info {
    @include footer-section($white,$green-grey-dark,65px,60px);
    .text-data {
      font-size: $size-small-i;
      font-weight: 200;
      color:$white;
    }
    .visible-xs-12{
      text-align: center;
    }
    .fa {
      margin-bottom: 4px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 33px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transform: translate(0, 0);
      .fa-phone:before {
        content: "\f098";//set phone icon
      }
      .fa-hotel:before {
        content: "\f236";//set hotel icon        
      }
      .fa-link:before {
        content: "\f14c";//set link icon
      }
    }
  }
}