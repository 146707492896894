/*General Div of form*/
.form-guest{
  padding-top: 15px;
  background-color: $white;
  margin-top: 15px;

  /*Styles to paragraph*/
  p{
    font-family: $fontRoboto;
    font-size: $size-small;
    margin: 0px 0 -15px;
    margin-left: 15px;
  }
  /*Styles to Labels*/
  label{
    font-family: $fontRoboto;
    font-size: $size-small;
    padding-top: $form-padding;
  }

  .panel-body{

    form{
      button{
        @include default-button-properities(10px,10px);
        @include hover-button();
        @include form-button-properties();
        font-family: $fontRoboto;
        font-size: $size-medium;
      }
      .content-inputs{

        margin-left: 15px;
        text-align: center;

        .row-input{
          margin-top: 15px;

          .input-icon{

            @include input-icon-properties();

          }

          input[type="text"]{
            @include step-4-inputs-form-settings();
          }

          input[type="email"]{
            @include step-4-inputs-form-settings();
          }

          input[type="number"]{
            @include step-4-inputs-form-settings();
          }


        }

        /* Styles to CHOSEN JS*/
        .chosen-container{
          border: 2px solid $green-grey-dark;
          height: 40px;
          margin-left: -30px;
          text-align: left;

          .chosen-single{
            border-radius: 0px;
            height: 27pt;
            border: none;

            span{
              background-color: transparent;
              color: gray;
              border-radius: 0px;
              border: none;
              font-size: $size-small;
              padding-top: 4px;
            }

          }

          ul.chosen-results {
            font-size: 10pt;
            font-family: $fontRoboto;

            li.highlighted {
              background-color: $green-grey-dark;
              background-image: none;

            }
          }
        }
      }


      .row-buttons{
        padding-top: $form-padding;
      }

      .btn-continue{
        @include add-after-icon("\f0a9");

      }
      .btn-back{
        @include add-before-icon("\f0a8");

      }

    }
  }

  /* Styles to inputs*/


  /* Styles to icon of input*/


  @media only screen and (min-width: 767px) {

    /* Styles to CHOSEN JS*/
    .chosen-container{
      border: 2pt solid $green-grey-dark;
      height: 30pt;

      .chosen-single{
        border-radius: 0px;
        height: 27pt;
        border: none;

        span{
          background-color: transparent;
          color: gray;
          border-radius: 0px;
          border: none;
          font-size: $size-small;
          padding-top: 4px;
        }

      }

      ul.chosen-results {
        font-size: 10pt;
        font-family: $fontRoboto;

        li.highlighted {
          background-color: $green-grey-dark;
          background-image: none;

        }
      }
    }
  }
}