body{

 padding-top: 0;
}

.slider-container{
  height: $slider-height;
}

.swiper-container{
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}
.img-slider{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: initial;
  background-position: center;
}

@media only screen and (max-width: 767px){


  .swiper-container {
    //This margin is necessary for mobile
    //margin-top: ($size-head-in-mobile)-20;

   // padding-top: 0;
  }

  .slider-container{
    height: $slider-height-mobile;

  }

}