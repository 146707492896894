/* Step 4 and Step-5 use the same classes, because both are forms */
.form-guest{

  .panel-body{

    form{

      .content-inputs{

        .popup-payment{

          @include pop-up($white,0px 0px 6px $green-grey-dark,49%);

          .background-popup{
            height: 600px;
            margin-top: -10px;
            width: 320px;

            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: initial;
            background-position: center;

          }

          > div{
            height: 600px;
            width: 320px;
          }
        }

        .expiration-content {

          .row-input {

            input{
              margin-left: 0;
            }
          }

          .row-expiration{
            margin-top: 15px;
            padding: 0;
          }
        }

        .question-container{
          background-color: $header-text-color;
          line-height: 40px;
          color:$white;
          margin-left: -30px;

          span{
            cursor: pointer;
          }
        }
      }
      .final-form-payment {

        text-align: center;

        .checkbox {

          label {
            color: $color-terms-form-payment;
            font-weight: bold;
          }

          p {
            font-size: 11px;
          }

          .checkbox-terms{
            position: relative;
            margin: 0;
            margin-right: -15px;

          }
        }

        .popup-terms{

          @include pop-up($white,0px 0px 6px $green-grey-dark,49%);

          .terms{
            padding: 20px;
            text-align: justify;
          }
        }
      }

      .text-area-properties{

        @include text-area-properties();

      }

      .label-expiration{
        padding-right: 46px;
      }

      .row-buttons{
        padding: 0;
        margin-top: 15px;
      }
    }
  }
}

