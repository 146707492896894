/*file scss for summary box section*/

/*content general for summary box*/
.summary_box {
  text-align: left;
  @media only screen and (min-width: 769px) {
    margin-top: 16px;
  }
}

/* class for top box*/
.box_top {
  background-color: $green-grey;
  float: top;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 23px;
  p {
    font-size: $size-medium;
    color: $white;
    margin-bottom: 0px;
    font-weight: 200;
    .row {
      .summary-title-mobile {
        p {
          font-size: $size-medium;
          color: $white;
          margin-bottom: 0px;
        }
      }

    }
  }
}


/*class for title of room reservation*/
.box_center {
  background-color: $green-grey-dark;
  float: top;
  padding-top:44px;
  padding-bottom:44px;
  padding-left: 23px;
  p {
    font-size: $size-large;
    color: $white;
    font-weight: 200;
    margin-bottom: 0px;
  }
}

/*class for box that it display the information about the reservation*/
.box_display {
  padding: 5% 14% 14% 8%;

  background-color: $white;
  margin-bottom: 3%;
  overflow: auto;
  .not-padding{
    padding-left: 0px;
    padding-right: 0px;
  }
  .hr-div {
    margin-top:15px;
    margin-bottom:15px;
    background-color: $green-grey;
    opacity: 0.5;
    width: 100%;
    height: 1px;
    float:left;
  }
  p {
    color:$green-grey;
    text-align: left;
    font-size: $size-small;
    font-weight: 200;
    margin-bottom: 0px;
    &.size-m{
      font-size: $size-medium;
      font-weight: 400;
    }
    &.position_right {
      text-align: right;
      @media (max-width: 990px) {  text-align: left;}
    }
  }
}

/*class for promo code section */
.promo_code {
  padding: 0px 0px 72px 17px;
  padding-top: 0px;
  padding-left: 17px;
  background-color: $white;
  margin-bottom: 5px;

  input[type="text"]{
    background-color: $white;
    border: 2pt solid $green-grey-dark;
    width: 132px;
    @media (max-width: 768px){  width: 120px;}
    @media (max-width: 990px) and (min-width: 769px){  width: 75px;}
    @media (max-width: 1199px) and (min-width: 991px) {  width: 100px;  }
    @media (min-width: 1200px) {  width: 132px  }
    height: 42px;
    margin: 0px;
    float:left;
    font-size: $size-small;
    color:$grey-medium;
    font-weight:200;
    padding-left: 15px;
    padding-right: 5px;
  }
  input[type="submit"]{
    height: 42px;
    @media (max-width: 768px){  width: 60px;}
    @media (max-width: 990px) and (min-width: 769px){ width: 45px; padding-left: 4px}
    @media (max-width: 1199px) and (min-width: 991px) {  width: 65px;  }
    @media (min-width: 1200px) {  width: 76px;  }
    margin: 0px;
    float:left;
    font-weight: 200;
    @include default-button-properities(10px,10px);
    @include hover-button();
  }
  p {
    color:$green-grey;
    text-align: left;
    margin-bottom: 2%;
    font-size: $size-medium;
    font-weight: 400;
    padding: 16px 0px 13px 2px;
  }
}

/*class for style the final seccion that it have the total and button*/
.final_total {
  background-color: $white;
  margin-bottom: 3%;
  font-size: $size-large;
  .button {
    background-color: $green-grey;
    @media (max-width: 990px) { padding: 5% 0% 6% 11%; }
    @media (min-width: 991px) { padding: 5% 0% 6% 25%; }
    a {
      color: $white;
      text-align: center;
      text-decoration: none;
      @include add-after-icon("\f0a9");
    }
    @include hover-button();
    font-weight: 200;
  }
  p {
    padding: 18px 10px 4px 26px;
    color:$green-grey;
    text-align: left;
    font-size: $size-large;
    font-weight: 200;
  }
}
/* Mobile Cart Shopping*/
@media only screen and (max-width: 767px) {

  #summary{

    overflow-x: hidden;
  }

  .box_top {
    .row {
      /*properties for the hide container*/
      .btn-hide-container{

        padding: 0;

        /*properties for the hide button*/
        .btn-hide {
          @include btn-hide-properties($white, 25px);
        }
      }

      .summary-title-mobile {
        margin-top: 17px;
        text-align: left;
        padding: 0;

        p{
          font-size:16px;
          padding: 0;
        }
      }
    }
  }

  .box_display {
    p.position_right {
      text-align: left;
    }
  }
}
