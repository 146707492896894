/*css for list*/
.steps {
	margin-top: 49px;
	font-size: 16px;
	font-weight: 200;
	background: $white;
	padding: 50px 0px 48px 0px;
    text-align: center;
	margin-bottom: 11px;
	ul {
	  text-decoration: none;
	  padding-left: 0px;
	  li {
	    text-decoration: none;
	    display: inline-block;
	    text-align: left;
		@media (max-width: 1191px){  width: 165px;}
		margin-top: 3px;
	  }
	}
	p {
		display: inline;
	}
	a {
 	  text-decoration: none;
 	  color: $grey-medium;
    }
}

/*css for p element*/
 .p-active {
	 p{
		 color:$green-grey;
		 font-weight: 400;
		 @include hover-button();
	 }
	 span{
		 @include step-span($green-grey,$white);
	 }
 }

.p-inactive {
	p{
		color:$grey-medium;
		font-weight: 400;
		@include hover-button();
	}
	span{
		@include step-span($light-grey,$grey-medium);
	}
}

