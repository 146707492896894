////////////////////////////////////////////////////////////////////////////
//-----------------THIS FILE CONTENT THE SCSS FOR STEP 3------------------//
////////////////////////////////////////////////////////////////////////////


.main-section {
  margin-top:15px;
  /*head-box for the head os section*/
  .head-box{
    float: top;
    @include add-setting-div-left ($green-grey,15px,90px,100%);
    color:$white;
    .text-icon {
      @include add-before-icon("\f054");
    }
  }
  .summary{
    width: 100%;
    padding: 20px;
    .title-icon {
      @include add-after-icon-c("\f054",$green-grey-dark);
    }
    .a1-icon {
      @include add-after-icon-c("\f101",$light-blue);
    }
    .a2-icon {
      @include add-after-icon("\f103");
    }
    a{
      text-decoration: none;
      &:hover{
        cursor:pointer;
      }
    }
  }
  .hr-green {
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-top:2px solid $green-grey-dark;
    position: relative;
    float:left;
  }

  .enhance{
    width: 100%;
    padding-bottom: 20px;
    a{
      text-decoration: none;
      &:hover{
        cursor:pointer;
      }
    }
    .row-custom {
      background-color: $light-grey-l;
      margin-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
      .full-content{
        margin-top:30px;
        .popup {
          @include pop-up(rgba(37, 49, 58, 0.0),none,49%);
        }
      }
      .row{
        padding-top: 8px;
      }
    }
    .container-img{
      img {
        z-index: 1;
      }
      .mask{
        display: none;
        z-index: 2;
        vertical-align: middle;
        position: absolute;
        bottom: 43%;
        left: 50%;
        transform: translate(-50%, 0);
        padding-left:10px;
        padding-right:10px;
        background-color: rgba(37, 49, 58, 0.8);
        color:$white;
      }
      &:hover {
        cursor:pointer;
        .mask{ display: block;}
        img {
          opacity: 0.9;
          box-shadow: 1px solid $green-grey-dark;
          -webkit-box-shadow: 0px 0px 5px 0px rgba(37, 49, 58,0.75);
          -moz-box-shadow: 0px 0px 5px 0px rgba(37, 49, 58,0.75);
          box-shadow: 0px 0px 5px 0px rgba(37, 49, 58,0.75);
        }
      }
    }
    .txt-area {
      margin-top: 10px;
      width: 100%;
      height: auto;
      float:left;
    }
    .forms-setting{margin-bottom: 10px;
    }

    .row-btns-step-3{

      margin: 0;
      margin-top: 15px;

      .row-input{
        //Icon container
        .input-icon{
          @include input-icon-properties();
        }

        //Input Container
        .container-input{
          padding-left: 0;
          @include chosen-properties();

          input[type="text"]{
            @include step-4-inputs-form-settings();
            margin-left: 0px;
          }
        }
      }

      .textarea-container{
        margin-top: 15px;

        .text-area-properties{
          @include text-area-properties();

        }
      }
    }

    .div-content-form {

      margin-top: 40px;
      height: 45px;
      position:relative;
      float:left;
      width: auto;
      padding:5px;


      /* Styles to icon of input*/
      span{
        border-radius: 0px;
        border: 2pt solid $green-grey-dark;
        background-color: $green-grey-dark;
        color: $white;
        //width: 20px;
      }
      .input-group{
        width: 270px;
      }

      input[type="date"]{
        @include step-4-inputs-form-settings();
      }
      .span-date{
        @include add-before-icon("\f073");
      }
      .span-room{
        @include add-before-icon("\f015");
      }
      .span-number{
        @include add-before-icon("\f0fe");
      }
      /* Styles to CHOSEN JS*/
      .chosen-container{
        border: 2pt solid $green-grey-dark;
        height: 30pt;
        max-width: 225px;
        .chosen-single{
          border-radius: 0px;
          height: 27pt;
          border: none;
          span{
            background-color: transparent;
            color: gray;
            border-radius: 0px;
            border: none;
            font-size: $size-small;
            padding-top: 4px;
          }
        }
        ul.chosen-results {
          font-size: 10pt;
          font-family: $fontRoboto;
          li.highlighted {
            background-color: $green-grey-dark;
            background-image: none;
          }
        }
      }
    }
  }

  button{
    @include default-button-properities(10px,10px);
    @include form-button-properties();
    @include hover-button();
    font-family: $fontRoboto;
    font-size: $size-medium;
  }

  .row-buttons{
    padding-top: $form-padding;
  }

  .btn-continue{
    @include add-after-icon("\f0a9");
  }

  .btn-back{
    @include add-before-icon("\f0a8");
  }




  /*main-box for the main enhances*/
  .main-box {
    margin-top: 20px;
    @include add-setting-div-left ($white,0px,65%,100%);
  }

  .second-box{
    margin-bottom: 10px;
    @include add-setting-div-left ($white,15px,35%,100%);
    margin-bottom: 10px;
  }


  /* Margin in inputs in resolution 970px */
  @media only screen and (max-width: 970px) {

    .row-input{

      margin-top: 15px;

    }
  }
}
