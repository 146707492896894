.row-content-index{
  background-color: $white;

  margin: 0;
  margin-top: 49px;

  .col-index-content{


    padding: 0px;
    margin: 0;

    p{

      text-align: justify;
      font-family: $fontRoboto;
      font-size: 15px;
      font-weight: lighter;
    }
  }

  ul{

    li{
      text-align: justify;
      font-family: $fontRoboto;
      font-size: 15px;
      font-weight: lighter;

    }

  }

}