/*file scss for summary box section*/

/*content general for summary box*/
.summary_calendar {
  text-align: left;
  @media only screen and (min-width: 769px) {
    margin-top: 16px;
  }
}

/* class for top box*/
.box_top_calendar {
  background-color: $green-grey;
  float: top;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 23px;
  height: 88px;
  p {
    font-size: $size-medium;
    color: $white;
    margin-bottom: 0px;
    font-weight: 200;
    .row {
      .summary-title-mobile {
        p {
          font-size: $size-medium;
          color: $white;
          margin-bottom: 0px;
        }
      }

    }
  }
}


/*class for title of room reservation*/
.box_center_calendar {
  background-color: $green-grey-dark;
  float: top;
  padding-top:5px;
  padding-bottom:5px;
  padding-left: 23px;
  height: 35px;
  p {
    font-size: $size-small;
    color: $white;
    font-weight: 200;
    margin-bottom: 0px;
  }
}

.box_display_calendar{
  background-color: $white;
  padding: 24px;
  @media (max-width: 1199px){
    text-align: center;
  }
  @media (min-width: 1200px){
    display: flex;
    justify-content: center;
  }

  .check_in{
    position: relative;
    display: inline-block;
    border: solid 1px $green-grey-dark;
    height: 120px;
    min-width: 100px;
    width: 45%;
    text-align: center;
    @media (min-width: 1200px){
      margin-right: 10px;
    }
    @media (max-width: 1199px){
      margin-bottom: 10px;
    }
    .p-label{
      background: $green-grey-dark;
      color:$white;
    }
    .p-number{
      font-size: $size-large-x;
    }
    .p-month{
      color: $green-grey-dark;
    }
  }
  .check_out {
    position: relative;
    display: inline-block;
    border: solid 1px $green-grey-dark;
    height: 120px;
    width: 45%;
    min-width: 100px;
    text-align: center;
    .p-label{
      background: $green-grey-dark;
      color:$white;
    }
    .p-number{
      font-size: $size-large-x;
    }
    .p-month{
      color: $green-grey-dark;
    }
  }

}


/*class for style the final seccion that it have the total and button*/
.final_total {
  background-color: $white;
  margin-bottom: 3%;
  font-size: $size-large;
  .button {
    background-color: $green-grey;
    @media (max-width: 990px) { padding: 5% 0% 6% 11%; }
    @media (min-width: 991px) { padding: 5% 0% 6% 25%; }
    a {
      color: $white;
      text-align: center;
      text-decoration: none;
      @include add-after-icon("\f0a9");
    }
    @include hover-button();
    font-weight: 200;
  }
  p {
    padding: 18px 10px 4px 26px;
    color:$green-grey;
    text-align: left;
    font-size: $size-large;
    font-weight: 200;
  }
}
/* Mobile Cart Shopping*/
@media only screen and (max-width: 767px) {

  #summary{

    overflow-x: hidden;
  }

  .box_top_calendar {
    .row {
      /*properties for the hide container*/
      .btn-hide-container{

        padding: 0;

        /*properties for the hide button*/
        .btn-hide {
          @include btn-hide-properties($white, 25px);
        }
      }

      .summary-title-mobile {
        margin-top: 17px;
        text-align: left;
        padding: 0;

        p{
          font-size:16px;
          padding: 0;
        }
      }
    }
  }

  .box_display {
    p.position_right {
      text-align: left;
    }
  }
}