
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/components-font-awesome/scss/font-awesome.scss";
@import "_variables";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: $gray;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  padding-bottom: 20px;
  background-color: $gray;
  background: $light-grey;
  font-family: $fontRoboto;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;

  /* Make the masthead heading the same height as the navigation */
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    padding-bottom: 19px;
  }
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  .btn {
    font-size: 21px;
    padding: 14px 24px;
  }
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
  p + h4 {
    margin-top: 28px;
  }
}

/*container custom*/
@media screen and (min-width: 1600px){
 .container {
   width: 1500px;
 }
}


/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: auto;
  }

  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }

  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}
/*css for specific sections*/
@import "_mixin.scss";
@import "_swiper";
@import "_steps_section";
@import "_top-bar";
@import "_summary_box";
@import "_summary_calendar";
@import "_top-bar";
@import "_menu";
@import "_footer";
@import "_step-4";
@import "_step-3";
@import "_step-2";
@import "_gallery";
@import "_step-5";
@import "_step-6";
@import "_step-1";
@import "_index-menu";
@import "_index";
@import "_customers-slider";
@import "language-options";

