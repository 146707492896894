/*THIS FILE CONTENT THE CSS FOR A GALLERYS*/

.content-gallery {
  padding:0px;
  width: 100%;
  height: auto;

  .image-display {
    width: 100%;
    height: auto;
    float:top;
    margin-bottom: 10px;
  }

  .image-inactive {
    width: 140px;
    height: 100px;
    margin-left: 10px;
    border: solid $green-grey-dark;
    float:left;
    display: inline-block;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      z-index: 1;
    }
    &:hover{
      cursor:pointer;
    }
    .selected {
      -webkit-filter: drop-shadow(2px 2px 20px $white);
    }
  }


}
