
.dropdown-index-menu{

  margin-left: auto;

}

@include menu-dropdown-style();


.index-menu{

  background-color: $header-text-color;
  border-radius: 0;
  border: none;
  height: $menu-height;

  .container {

    .row{

      .txt-menu{

        p{
          color:$white;
          font-family: $fontRoboto;
          line-height: 69px;
          font-size: 15px;
          font-weight: lighter;

        }
      }

      .index-content-rigth {

        display: flex;
        padding: 0;
        padding-right: 15px;

        .btn-language{

          margin-top: 17px;

        }


        a {
          margin-right: 15px;
          color: $white;
          font-size: 17px;
          font-weight: lighter;
          line-height: 63px;
          padding-top: 0;
          padding-bottom: 0;
          /* Include the arrows*/
          @include add-before-icon-menu("\f0a9");
          text-decoration: none;

          /* Settings the arrows position*/
          &:after {
            font-size: 28px;
            position: relative;
            top: 6px;
            color: $white;

          }
        }
      }
    }
  }
}


