$header-text-color: #25313a;
$icon-color: #333333;

$icon-font-path: '../fonts/';
$fontRoboto: 'Roboto', sans-serif;//default font for the site
$gray: #ccc;//gay
$light-grey: #f1f1f1;//light-grey
$light-grey-l: #f5f5f5;//light-grey more light
$white: #ffffff; //white
$green-grey: #3a454d; //green-grey
$green-grey-dark: #25313a; //green-grey-dark
$size-medium: 18px; //medium
$size-large: 24px; //large
$size-20: 20px;
$size-short:12px;
$size-small: 14px; //small
$size-small-i: 13px; // very small
$size-small-8: 10px; // very small
$size-large-x: 33px;// very large
$grey-medium: #92989c; //grey
$form-padding: 13px; // Padding for the form in step- 4
$light-blue: #5882FA;
$orange: #FAAC58;
$blue-link:#58aae6;
$size-head-in-mobile:101px; //The header size in mobile, in mobile the header will have fixed position.
$menu-height:69px;//Bar-menu Height
$slider-height:488px;
$slider-height-mobile:280px;
$font-size-logo-hotel:33px;
$color-terms-form-payment:#B9282F;

