///*This file content the mixin for css*////

/*add the icon after html from FontAwesome font*/
@mixin add-after-icon($code) {
  &:after{
    font-family: 'FontAwesome';//set the font for use the icon
    content: $code;//code of icon right arrow
    padding-left: 5px;
  }
}
@mixin add-before-icon-menu($code){
  &:after{
    font-family: 'FontAwesome';//set the font for use the icon
    content: $code;//code of icon right arrow
    margin-left:11px;
  }
}

@mixin add-icon-form($code){
  &:before{
    font-family: 'FontAwesome';//set the font for use the icon
    content: $code;//code of icon right arrow
  }
}



/*add the icon before html from FontAwesome font*/
@mixin add-before-icon($code) {
  &:before {
    font-family: 'FontAwesome'; //set the font for use the icon
    content: $code; //code of icon right arrow
    padding-right: 5px;
  }
}
/*add the icon after html from FontAwesome font with color*/
@mixin add-after-icon-c($code,$color) {
  &:after {
    font-family: 'FontAwesome'; //set the font for use the icon
    font-size: $size-small;
    content: $code; //code of icon right arrow
    padding-left: 5%;
    color: $color;
  }
}

/*add the icon after html from FontAwesome font with color*/
@mixin add-before-icon-c($code,$color) {
  &:before {
    font-family: 'FontAwesome'; //set the font for use the icon
    font-size: $size-20;
    content: $code; //code of icon right arrow
    color: $color;
    padding-right: 5px;
  }
}

/*set the hover with opacity to .50*/
@mixin hover-button(){
  &:hover{
    opacity: .50;
    cursor:pointer;
  }
}

/*general properities for submit*/
@mixin default-button-properities($pad-l,$pad-r){
  background-color: $green-grey-dark;
  color: $white;
  border: none;
  padding-left:$pad-l;
  padding-right:$pad-r;
}

@mixin form-button-properties(){
  height: 40px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

/*properities for step span (the circle with numbers)*/
@mixin step-span($color-bg,$color){
  background: $color-bg;
  border-radius: 1.1em;
  -moz-border-radius: 1.1em;
  -webkit-border-radius: 1.1em;
  color: $color;
  display: inline-block;
  line-height: 1.6em;
  margin-right: 2px;
  text-align: center;
  width: 2.2em;
  height: 2.2em;
  margin-top:5px;
  padding-top: 5.5px;
}


/* set color and size of social media icons*/
@mixin setSocialIcons ($color,$size) {

  .fa{
    color:$color;
    font-size:$size;
  }

}

@mixin setButtonProperties($background-color,$font-color,$border-color) {
  .btn-language {
    color: $font-color;
    background-color: $background-color;
    border-color: $border-color;
  }
}

  /* main properities for footer section*/
@mixin footer-section($color, $background-color,$pad-t,$pad-b) {
  background-color: $background-color;
  color: $color;
  padding: $pad-t 0px $pad-b 0px;
  height: auto;
}


@mixin add-setting-div-left ($bg-color,$padding,$height,$width){
  background-color: $bg-color;
  height: $height;
  width: $width;
  padding: $padding;
  .text-a {
     font-size: $size-large;
     font-weight: 200;
  }
  .text-b {
    color:$green-grey;
    font-size: $size-small;
    font-weight: 200;
  }
  .text-b-circle-a {
    color:$green-grey;
    font-size: $size-medium;
    @include add-after-icon-c("\f0a3",$green-grey-dark);
  }
  .text-b-circle-b {
    color:$green-grey;
    font-size: $size-medium;
    @include add-after-icon-c("\f0a3",$light-blue);
  }
  .text-b-circle-c {
    color:$green-grey;
    font-size: $size-medium;
    @include add-after-icon-c("\f0a3",$orange);
  }
  .text-c{
    font-size: 9pt;
    margin: 0px;
  }
  /*class to delete margins*/
  .not-margin{
    margin: 0px;
  }
  /*class to set a margin*/
  .mar-bottom{
    margin-bottom: 29px;
  }
  .row-a{
    margin: 0px;
    padding-left: 31px;
    margin-top: 7px;
    @media (max-width: 990px){
      margin-top: 0px;
    }
  }
  .row-b{
    margin: 0px;
    padding-left: 28px;
  }
  .row-c{
    margin: 0px;
    padding-top: 10px;
    padding-left: 0px;
    @media (max-width: 990px){
      padding: 0px;
      margin: 0px;
    }
  }
  .row-d{
    @media (max-width: 990px){
      padding-left: 31px;
      padding-top: 10px;
    }
  }

}

@mixin general-setting-input ($width,$height,$margin-t,$margin-b,$margin-l,$margin-r){
  width:$width;
  height: $height;
  background-color: $white;
  border: 2pt solid $green-grey-dark;
  font-size: $size-small;
  color:$grey-medium;
  margin-top: $margin-t;
  margin-bottom: $margin-b;
  margin-right: $margin-r;
  margin-left: $margin-l;
}


@mixin set-logo-hotel-font-size($size){
  .logo-hotel{
    font-size: $size;
  }
}

@mixin step-4-inputs-form-settings(){
  background-color: $white;
  border: 2px  solid $green-grey-dark;
  height: 40px;
  font-size: $size-small;
  color:$grey-medium;
  border-radius: 0px;
  margin-left: -15px;
}

@mixin btn-setting-step-2($bg-color,$border,$mar-b,$display,$bg-color-h,$color-h,$icon){
  background-color: $bg-color;
  border: $border;
  border-bottom: 0px;
  padding-left:10px;
  padding-right:10px;
  margin-bottom: $mar-b;
  display: $display;
  height: 37px;
  width: 148px;
  line-height: 34px;
  font-weight: 200;
  /*for the screen more small*/
  @media (max-width: 768px) and (min-width: 426px){
    width: 110px;
  }
  /*for the mobile screen*/
  @media (max-width: 425px){
    width: 100px;
    padding-left:0px;
    padding-right:0px;
  }
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  color:$green-grey-dark;
  &:hover {
    background-color: $bg-color-h;
    color:$color-h;
    cursor:pointer;
  }
  @include add-before-icon($icon);
  &:before {margin-right: 4px;}
}

@mixin div-custom-st2 ($width, $height, $display, $float,$pad-left){
  width: $width;
  display: $display;
  float: $float;
  padding-left: $pad-left;
  height: $height;
}

@mixin fill-circle($size, $radius, $br, $bg-color){
  width: $size;
  height: $size;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $br;
  background-color: $bg-color;
}


@mixin setPropertiesMenu($background-color,$text-color){

  background-color: $background-color;
  color: $text-color;

}

@mixin pop-up($bg-div,$shadow,$width-div){
  #close {
    float:right;
    @include add-after-icon ("\f00d");
    text-decoration: none;
    color:$green-grey-dark;
    width: $width-div;
    text-align: right;
    margin-right: 15px;
    z-index: 2;
    position: absolute;
  }
  background: rgba(37, 49, 58, 0.8);
  position: fixed;
  display: none;
  z-index: 5000;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  > div {
    z-index: 1000;
    position: fixed;
    background: $bg-div;
    box-shadow: $shadow;
    padding: 10px 0px;
    /* Width of popup can be changed */
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    text-align: center;
    /*responsive sizes*/
    @media (min-width: 1441px){  max-width: 970px; left: 50%;}
    @media (max-width: 1440px) and (min-width: 1025px){  max-width: 750px; left: 50%; }
    @media (max-width: 1024px) and (min-width: 769px){  max-width: 616px; left: 48%; }
    @media (max-width: 768px) and (min-width: 426px){  max-width: 720px; left: 45%; }
    @media (max-width: 425px) and (min-width: 376px){  max-width: 395px; left: 45%; }
    @media (max-width: 375px){  max-width: 345px; left: 45%; }
  }
}

@mixin btn-hide-properties($color,$size){

  @include add-after-icon("\f00d");
  background-color: transparent;
  z-index: 10;
  border: none;
  font-size: 32px;

    &:after{
      color: $color;
      font-size: $size;
    }
    &:focus{
      outline: none;
      box-shadow: none;
    }
}

@mixin input-icon-properties(){

  background-color: $header-text-color;

  i{
    color:$white;
    line-height: 40px;
    text-align: center;
    display: block;
  }
}
@mixin chosen-properties(){

  /* Styles to CHOSEN JS*/
  .chosen-container{
    border: 2px solid $green-grey-dark;
    height: 40px;
    margin: 0;
    padding: 0;
    text-align: left;

    .chosen-single{
      border-radius: 0px;
      height: 36px;
      border: none;
     // line-height: 36px;

      span{
        background-color: transparent;
        color: gray;
        border-radius: 0px;
        border: none;
        font-size: $size-small;
        padding-top: 4px;
      }

    }

    ul.chosen-results {
      font-size: 10pt;
      font-family: $fontRoboto;

      li.highlighted {
        background-color: $green-grey-dark;
        background-image: none;

      }
    }
  }
}

/* Text area properties STEP-3, STEP-4, STEP-5 */

@mixin text-area-properties(){
  resize: none;
  background-color: $white;
  border: 2px  solid $green-grey-dark;
  border-radius: 0;
  font-size: $size-small;

}

@mixin menu-dropdown-style(){

  /* Styles for the Dropdowns*/
  .options-dropdown-menu{

    padding: 10px;
    width: 210px;
    background-color: $header-text-color;
    margin-top: -1px;
    border:none ;
    border-radius: 0;
    form{

      text-align: center;

      label{
        color: $white;
      }

      input{
        border-radius: 0px;
        margin-top: 5px;
      }

      button{
        margin-top: 5px;
        font-family: $fontRoboto;
        background-color: $light-grey;
        border: none;
      }
    }
  }
  /*-----------------------------------*/
}

