.content-left {
  margin-top:18px;
  .content-form {
    @include add-setting-div-left ($white,0px,100%,100%);
    padding-left: 32px;
    padding-top: 30px;
    overflow: auto;
    padding-bottom: 14px;
    margin-bottom: 34px;

    .float-left{
      width: 60%;
      float: left;
    }

    .cont-date {
      width: 100px;
      display: inline-block;
      margin-right: 20px;
      input[type="text"]{
        @include general-setting-input(100%,35px,0px,20px,0px,10px);
      }
      p {
        width: 93px;
      }
    }
    .cont-number {
      width: auto;
      display: inline-block;
      select {
        @include general-setting-input(48px,35px,0px,0px,0px,0px);
      }
    }
    .float-right {
      width: 40%;
      float: right;
      padding-left: 22px;
      .cont-number-b {
        width: 93px;
        display: inline-block;
        select {
          @include general-setting-input(50px,35px,0px,0px,0px,0px);
        }
      }
    }
    .btn-section{
      width: 100%;
      float: left;
      input[type="submit"] {
        width: auto;
        text-decoration: none;
        min-width: 88px;
        height: 40px;
        margin-right: 55px;
        margin-top: 5px;
        margin-bottom: 40px;
        font-weight: 200;
        @include default-button-properities(16px,17px);
        @include hover-button();
      }
    }
    /*style for the selects*/
    .chosen-container{
      border: 2pt solid $green-grey-dark;
      height: 35px;
      width: 50px !important;
      color:$white;
      background-color: $green-grey-dark;

      .chosen-single{
        border-radius: 0px;
        height: 31px;
        border: none;
        background: $white;
        box-shadow: none;
        span{
          background-color: transparent;
          color: gray;
          border-radius: 0px;
          border: none;
          font-size: $size-small;
          padding-top: 4px;
          color:$green-grey-dark;
        }
      }

      ul.chosen-results {
        font-size: 10pt;
        font-family: $fontRoboto;

        li.highlighted {
          background-color: $green-grey-dark;
          background-image: none;

        }
      }
    }
  }

  /*css for the results*/
  .room-info {
    margin-top:18px;
    overflow: auto;
    @include add-setting-div-left ($white,0px,auto,auto);
    .main-display {

      /*custom setting for div*/
      @media (min-width: 1441px) {
         .picture {
           @include div-custom-st2 (332px, 225px, inline-block, left,0px);
         }
         .info {
           margin-top: 31px;
           @include div-custom-st2 (500px, 225px, inline-block, left,29px);
         }
      }
      @media (max-width: 1440px) and (min-width: 1025px){
        .picture {
          @include div-custom-st2 (332px, 225px, inline-block, left,0px);
        }
        .info {
          margin-top: 31px;
          @include div-custom-st2 (450px, 225px, inline-block, left,29px);
        }
      }
      @media (max-width: 1024px) and (min-width: 769px){
        .picture {
          @include div-custom-st2 (230px, 225px, inline-block, left,0px);
        }
        .info {
          margin-top: 10px;
          @include div-custom-st2 (385px, 225px, inline-block, left,10px);
        }
      }
      @media (max-width: 768px) and (min-width: 426px){
        .picture {
          @include div-custom-st2 (310px, 225px, inline-block, left,0px);
        }
        .info {
          @include div-custom-st2 (500px, 225px, inline-block, left,10px);
        }
        .verticar {
          border-right:1px solid $green-grey-dark;
        }
      }
      @media (max-width: 425px) and (min-width: 376px){
        .picture {
          @include div-custom-st2 (300px, 225px, inline-block, left,0px);
        }
        .info {
          @include div-custom-st2 (400px, 300px, inline-block, left,10px);
        }
      }
      @media (max-width: 375px){
        .picture {
          @include div-custom-st2 (300px, 225px, inline-block, left,0px);
        }
        .info {
          @include div-custom-st2 (300px, 330px, inline-block, left,10px);
        }
      }
      @media (min-width: 768px){
        .verticar {
          border-right:1px solid $green-grey-dark;
        }
      }
      .content-a{
        display: inline-block;
        position: relative;
        float: left;
        width: 52%;
        height: auto;
        border-right: 2px $grey-medium solid;
      }
      .content-b{
        display: inline-block;
        position: relative;
        float: left;
        width: 48%;
        height: auto;
        padding-left: 68px;
        @media (max-width: 425px){
          padding-left: 25px;
        }
        padding-top: 5px;
      }
    }
    .info-display {
      margin-top:24px;
      height: auto;
      display: inline-block;
      width: 100%;
      .rate {
        margin-bottom: 45px;
        display: inline-block;
        width: 100%;
        padding-top: 10px;
        .row-custom {
          background-color: $light-grey-l;
          margin-top: 13px;
          overflow: auto;
        }
        a{
          text-decoration: none;
          &:hover{
            cursor:pointer;
          }
        }
      }
      .detail {
        display: inline-block;
        margin-bottom: 45px;
        width: 100%;
      }
      .photo {
        display: inline-block;
        margin-bottom: 45px;
        background-color: $light-grey;
        height: 420px;
        width: 100%;
        .swiper-container{margin-top: 0px;}
        /*size more small for mobile*/
        @media (max-width: 425px){
          height: $slider-height-mobile;
        }
      }
      .hr-green {
        margin: 0px;
        padding: 0px;
        width: 100%;
        border-top:5px solid $green-grey-dark;
        position: relative;
        float:left;
      }

      /*scss bottons*/
      .btn-rate {
        display: inline-block;
        float: left;
        margin-left: 30px;
        @include btn-setting-step-2($white,2px solid $green-grey-dark,0px,inline-block,$green-grey-dark,$white,"\f236");
        @media (max-width: 375px){
          width: 80px;
        }
      }

      .btn-detail {
        display: inline-block;
        float: left;
        margin-left: 7px;
        @include btn-setting-step-2($white,2px solid $green-grey-dark,0px,inline-block,$green-grey-dark,$white,"\f036");
        @media (max-width: 375px){
          width: 80px;
        }
      }
      .btn-photo {
        display: inline-block;
        float: left;
        margin-left: 7px;
        @include btn-setting-step-2($white,2px solid $green-grey-dark,0px,inline-block,$green-grey-dark,$white,"\f03e");
        @media (max-width: 375px){
          width: 80px;
        }
      }
      .a-custom{
        font-size: $size-short;
        margin-left: 5px;
        font-weight: bold;
        color:$blue-link;
      }

      .btn-reserve{
        width: 150px;
        height: 32pt;
        font-weight: 200;
        @include default-button-properities(10px,10px);
        @include hover-button();
      }

      //************************** scss for pop up **************************//
      .container-pop {
        max-width: 100%;
        margin: 20px auto;
        padding: 30px;
        text-align: center;
        background: $green-grey-dark;
        border: 1px solid $green-grey-dark;
      }

      /*class for delete a paddings*/
      .not-padding{
        padding: 0px;
      }

      .popup {
        @include pop-up ($white,0px 0px 6px $green-grey-dark,49%);
      }
      .content-sm-box{
        height:auto;
        margin:15px;
        padding-left: 30px;
        text-align:left;
        position:relative;
        float:left;
        input[type="submit"]{
          float:right;
          width: auto;
          height: 30pt;
          margin-right: 15px;
          @include default-button-properities(10px,10px);
          @include hover-button();
        }

        /*responsive sizes*/
        @media (min-width: 1441px){  width:45%;}
        @media (max-width: 1440px) and (min-width: 1025px){  width:45%; }
        @media (max-width: 1024px) and (min-width: 769px){  width:90%; }
        @media (max-width: 768px) and (min-width: 426px){  width:45%; }
        @media (max-width: 425px) and (min-width: 376px){  width:90%; padding-left: 0px;}
        @media (max-width: 375px){  width:80%; padding-left: 0px;}
      }
      .content-pop-full{
        width: 100%;
        text-align:left;
        position:relative;
        float:left;
      }

      .table-pop {
        thead {
          background-color: $green-grey-dark;
          color:$white;
        }
        th, td {
          /* to center table in big screen */
          @media (min-width: 426px){  padding-left:5%; }
        }
      }
    }
  }
}

