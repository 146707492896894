/*********this file content the styles for the step 6 section*********/

.container-s6 {
  /*general properties*/
  margin-top:18px;
  overflow: auto;
  @include add-setting-div-left ($white,0px,auto,100%);

  /*style for the main div*/
  .box-main-top {
    width: 100%;
    padding: 10px;
    height: auto;
    .title-icon {
      @include add-before-icon-c("\f054",$green-grey-dark);
    }
  }

  /*separator style*/
  .hr-div{
    margin: 0px;
    padding: 0px;
    width: 100%;
    border-top:2px solid $green-grey-dark;
    position: relative;
    float:left;
  }

  /*features for second sections*/
  .box-second-left{
    width: 50%;
    @media (max-width: 600px){
      width: 100%;
    }
    padding: 10px;
    position: relative;
    float: left;
    height: auto;
    &.box-form{
      width: 185px;
    }

  }

  input[type="text"]{
    background-color: $white;
    border: 2pt solid $green-grey-dark;
    @media (max-width: 768px){  width: 144px;}
    @media (max-width: 990px) and (min-width: 769px){  width: 140px;}
    @media (max-width: 1199px) and (min-width: 991px) {  width: 144px;  }
    @media (min-width: 1200px) {  width: 144px  }
    height: 40px;
    margin: 0px;
    float:left;
    font-size: $size-small;
    color:$grey-medium;
    font-weight:200;
    padding-left: 15px;
    padding-right: 5px;
  }

  /*features for the submit*/
  input[type="submit"]{
    @include default-button-properities(10px,10px);
    @include form-button-properties();
    @include hover-button();
    font-family: $fontRoboto;
    font-size: $size-medium;
    min-width: 140px;
    margin-bottom: 15px;
    &.send{
      height: 40px;
      @media (max-width: 768px){  width: 50px;}
      @media (max-width: 990px) and (min-width: 769px){ width: 65px; padding-left: 4px}
      @media (max-width: 1199px) and (min-width: 991px) {  width: 65px;  }
      @media (min-width: 1200px) {  width: 65px;  }
      margin: 0px;
      float:left;
      font-weight: 200;
    }
  }
  /*features for the submit*/
  button[type="submit"]{
    @include default-button-properities(10px,10px);
    @include form-button-properties();
    @include hover-button();
    font-family: $fontRoboto;
    font-size: $size-medium;
    min-width: 140px;
    margin-bottom: 15px;
    &.send{
      height: 40px;
      @media (max-width: 768px){  width: 50px;}
      @media (max-width: 990px) and (min-width: 769px){ width: 65px; padding-left: 4px}
      @media (max-width: 1199px) and (min-width: 991px) {  width: 65px;  }
      @media (min-width: 1200px) {  width: 65px;  }
      margin: 0px;
      float:left;
      font-weight: 200;
      @include add-after-icon("\f003");
    }
  }
}