.language.english {

  background-color: $header-text-color;
  list-style: none;
  text-align: center;

  #language {

    .custom-select-selected {
      padding-left: 10px;
      padding-right: 10px;


      a {
        margin: 0;
        line-height: 62px;
        text-decoration: none;
      }
    }
    .custom-select-options.clearfix {


    /* DEFAULT BEHAVIOR IN MOBILE*/
      display: none;
    /*-------------------------- */

      background-color: $header-text-color;
      padding: 0 10px;
      margin: 0;
      line-height: 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          background-color: $header-text-color;
          color: $white;
          margin: 0;

          a {
            margin: 0;
            padding: 0;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

/* Behavior in mobile */
@media only screen and (max-width: 767px){

  .opt-language{

    .language.english {

      #language {

        .custom-select-selected {

          a {
            margin-left: 5px;
          }
        }
      }

      background-color: $white;
      width: 100%;
      text-align: left;

      li{
        margin-left: 5px;
        background-color: $white;
        text-align: center;

      }
    }
  }
}