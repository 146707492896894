.slider-container-customers{

  height: 400px;
  margin-bottom: 15px;
}
.swiper-container-customers{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

footer{
  .footer-custom{
    margin-top: 35px;
  }
}

.img-slider{
  a{
    padding:5px;
    position: relative;
    background-color: transparent;
    text-decoration: none;
    color:$green-grey-dark;
    &:hover{
      cursor: pointer;
      background: rgba(58,69,77,0.8);
    }
    background: rgba(58,69,77,0.5);
  }
}

