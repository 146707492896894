#header-container{
  height: 150px;
}

.logo-hotel{
  margin: 0;
  color: $header-text-color;
  font-family: $fontRoboto;
  font-size: $font-size-logo-hotel;
  font-weight: lighter;
  line-height: 149px;
}

.btn-language{
  width: 95px;
  height: 35px;
  font-size: 17px;
  font-weight: 300;
  border-style: solid;
  border-width: 2px;

}


@include setButtonProperties($light-grey,$header-text-color,$header-text-color);


.right-section{

  @include setSocialIcons($header-text-color,36px);
  margin-top: 57px;
  padding-right:10px;
  text-align: right;

}

@media only screen and (max-width: 767px){

  #header-container {

    background-color: $header-text-color;
    width: 100%;
    height: $size-head-in-mobile;
    top:0;


    .left-section{
      margin-top: 22px;
    }

    .mid-section{

      text-align: center;

      .logo-hotel{
        color: $white;
        margin-top: 0;
        line-height: $size-head-in-mobile;
      }
    }

    .right-section {

      margin: 0;
      margin-top: 22px;

      .btn-cart{
        border: none;
        border-radius: 4px;
        float: right;
        margin-right: 15px;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 53px;
        height: 46px;
        padding: 0;

        @include add-before-icon("\f07a");

        &:before{
          color:white;
          font-size: 30px;
          padding: 0;
          margin: 0;
        }
        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
  .summary-box-mobile {
    background-color: $white;
    float: right;
    z-index: 10;
    position: fixed;
    padding: 0;
  }
}

@media only screen and (max-width: 620px) {
  .logo-hotel{
    font-size: ($font-size-logo-hotel)-5;
  }
}


@media only screen and (max-width: 365px){

  .logo-hotel{
    font-size: ($font-size-logo-hotel)-10;
  }

  #header-container{

    .left-section{
      margin-top: 26px;

      #hamburger{

        span{
          height: 2px;
          width: 28px;
        }

      }

    }
    .right-section {

      .btn-cart {

        &:before {
          font-size: 25px;

        }
      }
    }
  }
}








