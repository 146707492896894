/*********This file content the styles for the step 1**********/

.box-main{
  margin-top: 16px;
  color:$white;
  @include add-setting-div-left ($light-grey,0px,auto,100%);
  .box-top{
    width: 100%;
    height: 88px;
    background-color: $green-grey;
    padding: 24px;
    z-index: 100;
    @media (max-width: 991px) and (min-width: 768px){
      height: 135px;
    }
    @media (max-width: 736px) and (min-width: 500px){
      height: 135px;
      padding-left: 18%;
    }
    @media (min-width: 321px) and (max-width: 499px) {
      height: 135px;
      padding-left: 10%;
    }
    @media (max-width: 320px) {
      height: 135px;
      padding-left: 4%;
    }
    /*div of 50% */
    .div-half{
      width: 50%;
      min-width: 296px;
      @media (max-width: 375px) {
        min-width: 262px;
      }
      position: relative;
      float: left;
      text-align: left;
      padding-left: 5%;
      @media (max-width: 991px) and (min-width: 768px){
        margin-top: 10px;
        margin-left: 90px;
      }
      @media (max-width: 736px) {
        margin-top: 10px;
        margin-left: 0px;
      }
      &.mar {
        @media (max-width: 991px) and (min-width: 768px) {
          margin-left: 140px;
        }
        @media (min-width: 376px) and (max-width: 736px) {
        margin-left: 50px;
        }
        @media (min-width: 321px) and (max-width: 375px) {
          padding-left: 49px;
        }
        @media (max-width: 320px) {
          padding-left: 47px;
        }
      }
      .div-icon-left{
        height: 40px;
        width: 35px;
        position: relative;
        float: left;
        display: inline-block;
        margin-right: 15px;
        @media (max-width: 375px) {
          margin-right: 0px;
        }
        font-size: 28px;
        text-decoration: none;
        color:$white;
        @include add-after-icon("\f0a8");
      }
      .div-select{
        width: 172px;
        display: inline-block;
        position: relative;
        float: left;
      }

      .box-label{
        height: 40px;
        width: 85px;
        position: relative;
        float: left;
        display: inline-block;
        margin-left: 4px;
        padding-top: 9px;
        color:$white;
        @media (max-width: 991px) and (min-width: 768px){
          display: none;
        }
        @media (max-width: 736px) {
          display: none;
        }
      }

      .div-icon-right{
        height: 40px;
        width: 35px;
        position: relative;
        float: left;
        display: inline-block;
        padding-left: 17px;
        @media (max-width: 375px) {
          padding-left: 0px;
        }
        font-size: 28px;
        text-decoration: none;
        color:$white;
        @include add-after-icon("\f0a9");
      }

      /*style for the selects*/
      .chosen-container{
        border: 2pt solid $green-grey-dark;
        height: 30pt;
        color:$white;
        background-color: $green-grey-dark;

        .chosen-single{
          border-radius: 0px;
          height: 27pt;
          border: none;
          background: $green-grey-dark;
          box-shadow: none;
          span{
            background-color: transparent;
            color: gray;
            border-radius: 0px;
            border: none;
            font-size: $size-small;
            padding-top: 4px;
            color:$white;
          }
        }

        ul.chosen-results {
          font-size: 10pt;
          font-family: $fontRoboto;

          li.highlighted {
            background-color: $green-grey-dark;
            background-image: none;

          }
        }
      }

    }

  }
  .box-calendar{
    width: 100%;
    height: auto;
    overflow: auto;
    .table-calendar {
      .box-table {
        margin-bottom: 0px;
        width: 100%;
        height: 400px;
      }
      .background {
        background-color: $green-grey-dark;
        width: 100%;
        height: 35px;
        .box-head {
          background-color: $green-grey-dark;
          .text-b {
            color: $white;
          }
          width: 100%;
        }
      }
      .week-day {
        width: 14.2857143%;
        height: 35px;
        font-size: $size-small;
        font-weight: 200;
        text-align: center;
        color: $white;
        background-color: $green-grey-dark;
        border: none;
        float: left;
        padding-top: 6px;
        .small {
          display: none;
          @media (max-width: 991px) {
            display: inline;
          }
        }
        .big {
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
      .background-days{
        width: 100%;
        background-color: $white;
        height: auto;
        .row-day {
          .day {
            padding-top: 5px;
            width: 14.2857143%;
            height: 85px;
            font-size: $size-small;
            font-weight: 200;
            text-align: center;
            color: $green-grey;
            background-color: $white;
            border: 2px solid $light-grey;
            float: left;
            &:hover {
              background-color: rgba(37, 49, 58, 0.1);
            }
            &:first-child {
              border-left: 0px;
            }
            &:last-child {
              border-right: 0px;
            }
            .not-margin {
              padding: 0px;
              margin: 0px;
            }
            /*states*/
            &.disabled {
              color: $gray;
            }
            &.selected {
              color: $green-grey-dark;
              background-color: rgba(37, 49, 58, 0.3);
            }
            .small {
              display: none;
              @media (max-width: 991px) {
                display: inline;
              }
            }
            .big {
              @media (max-width: 991px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}